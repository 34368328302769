.form-control-sm.form-control.ng-select {
  border: none;
  padding: 0;


}

.form-control-sm.form-control.ng-select .ng-select-container {
  min-height: auto;
  height: 32px;
}


.alert-sm {
  padding: 0.25rem 0.5rem !important;
}

.badge-danger-invert {
  background-color: #fff;
  color: red;
  display: flex;
  align-items: center;
  margin-top: 50%;
  line-height: 10px;
  font-size: 14px;
}

.list-group-slick .list-group-item {
  border-width: 0px
}

.list-group-slick .list-group-item:hover {
  background-color: #f8f9fa;
}