$enable-negative-margins: true;
@import 'animate.css/animate.min.css';
@import "@ng-select/ng-select/themes/default.theme.css";

.cursor-alias {
  cursor: alias;
}

.cursor-all-scroll {
  cursor: all-scroll;
}

.cursor-auto {
  cursor: auto;
}

.cursor-cell {
  cursor: cell;
}

.cursor-context-menu {
  cursor: context-menu;
}

.cursor-col-resize {
  cursor: col-resize;
}

.cursor-copy {
  cursor: copy;
}

.cursor-crosshair {
  cursor: crosshair;
}

.cursor-default {
  cursor: default;
}

.cursor-e-resize {
  cursor: e-resize;
}

.cursor-ew-resize {
  cursor: ew-resize;
}

.cursor-grab {
  cursor: -webkit-grab;
  cursor: grab;
}

.cursor-grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.cursor-help {
  cursor: help;
}

.cursor-move {
  cursor: move;
}

.cursor-n-resize {
  cursor: n-resize;
}

.cursor-ne-resize {
  cursor: ne-resize;
}

.cursor-nesw-resize {
  cursor: nesw-resize;
}

.cursor-ns-resize {
  cursor: ns-resize;
}

.cursor-nw-resize {
  cursor: nw-resize;
}

.cursor-nwse-resize {
  cursor: nwse-resize;
}

.cursor-no-drop {
  cursor: no-drop;
}

.cursor-none {
  cursor: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-progress {
  cursor: progress;
}

.cursor-row-resize {
  cursor: row-resize;
}

.cursor-s-resize {
  cursor: s-resize;
}

.cursor-se-resize {
  cursor: se-resize;
}

.cursor-sw-resize {
  cursor: sw-resize;
}

.cursor-text {
  cursor: text;
}

.cursor-w-resize {
  cursor: w-resize;
}

.cursor-wait {
  cursor: wait;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.cursor-zoom-out {
  cursor: zoom-out;
}

html,
body {
  height: 100vh !important;
  background-color: #efefef !important;
  overflow: hidden;
}

.btn-slick {
  background: transparent;
  border: 0;
}

.btn-primary {
  background-color: #207aad !important;
  border-color: #207aad !important;
}

.list-group-item.active {
  background-color: #207aad !important;
  border-color: #207aad !important;
  font-weight: bold;
  color: white !important;
}

.primary {
  color: #207aad !important;
}

.primary-bg {
  background-color: #1e2833 !important;
}

.badge.bg-warning {
  color: #495057 !important;
}

.badge-inactiv {
  color: #495057 !important;
  background-color: #00000000;
}

.list-group-item.active .badge-inactiv {
  color: white !important;
}

.nav-icon {
  color: #ffffff;
  align-items: center;
  padding-right: .5rem;
  padding-left: .5rem;
  margin: 1% 0%;
  display: block;
  transition: 0.2s linear;
}

.nav-icon:hover {
  color: #384d63;
  transition: 0.2s linear;
}

.nav-item-icon {
  font-size: 28px;
}

.topbar-divider {
  width: 0;
  border-right: 1px solid #9dc2d9;
  height: 2.375rem;
  margin: auto 1rem;
}

.nav-user-img {
  width: 45px;
  position: relative;
  display: inline-block;
  margin: 4px -8px 2% 0px;
  transition: 0.2s linear;
}

.round-nav {
  line-height: 32px;
  font-size: 14px;
  color: #fff;
  width: 35px;
  height: 35px;
  display: inline-block;
  text-align: center;
  background: #384d63;
  border-radius: 100%;
  border: 2px solid #384d63;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  transition: 0.2s linear;
}

.nav-item.nav-item-icon.active .nav-icon {
  color: #207aad;
}

.sidebarBackground {
  width: 100%;
  margin-top: -100px;
  background-color: white;
  background-size: cover;
  position: relative;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
  z-index: 10;
}

.bd-content-welcome {
  margin-top: 45px
}

.debug {
  background-color: red
}

.form-control-sm.form-control.ng-select {
  border: none;
  padding: 0;
}

.form-control-sm.form-control.ng-select .ng-select-container {
  min-height: auto;
  height: 32px;
}

.alert-sm {
  padding: 0.25rem 0.5rem !important;
}

.badge-danger-invert {
  background-color: #fff;
  color: red;
}

.unselectable {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

.handle {
  cursor: move;
}

.logo-preview {
  max-height: 75px;
  max-width: 100%;
  border: 1px dashed darkgray;
  padding: 3px;
  margin-top: 3px;
}

a:not([href]),
a:not([href]):hover,
nav a {
  color: inherit;
  text-decoration: none;
}

@media print {

  // changed in Bootstrap 3 to 4

  * {
    font-size: 14px;
  }

  body {
    overflow: initial;
  }

  html,
  body {
    background-color: white !important;
  }

  app-root>* {
    display: none;
  }

  app-root .content {
    display: block;
    background-color: white;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 550px !important;
}


.cdk-drag-preview {
  padding: 0.5rem 1rem !important;
  background-color: #fffa;
}


.set-view-card>.card-body {

  height: calc(100vh - 230px);
  overflow: auto;
}

.set-view-card>.card-header {
  background-color: white;
  border-bottom: 0;
}


.set-view-card.catalog>.card-body {

  height: calc(100vh - 275px);
}

.hover-mark:hover,
.hover-mark:hover>* {
  background-color: #207aad !important;
  color: white !important;
}

.hover-mark:hover .text-primary {
  color: white !important;
}

.hover-mark:hover .badge-inactiv {
  color: white !important;
}

.selected-mark,
.selected-mark>* {
  .text-primary {
    color: white !important;
  }

  .badge-inactiv {
    color: white !important;
  }

  background-color: #207aad !important;
  color: white !important;
}

.round-nav {
  border-color: #207aad !important;
  background-color: #207aad !important;
}